<template>
    <div>
        <div class="div_ExamList com_Box">
            <div class="Tit">
                <div class="Tit-title">近期报名</div>
                <a href="javascript:void(0);" @click="moreExam()" class="float-right" style="color: #3877bd">查看更多>></a>
            </div>
            <div class="List">
                <div v-for="(item, i) in examList" :key="i" class="flexList div_enrollList" @click="goToLogin">
                    <div class="div_enrollList_tag" :class="item.isOpen == 1 ? 'tag-success' : 'tag-danger'">
            <span class="enrollList-text">{{
              item.isOpen == 1 ? "正在报名" : item.isOpen == 2 ? "尚未开始":"报名结束"
            }}</span>
                    </div>
                    <div class="div_enrollList_center line1" :title="item.ksmc">{{ item.ksmc }}</div>
                    <div class="div_ExamList_time" :title="item.ksmc">
                        <i class="iconfont icon-shijian mr-2"></i>报名时间：{{
                        item.bmkssj
                        }}至{{ item.bmjssj }}
                    </div>
                    <div class="div_ExamList_button ExamList_button_info">
            <span class="enrollList-text">{{
                item.isOpen == 1 ? "正在报名" : item.isOpen == 2 ? "尚未开始":"报名结束"
            }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Swiper from "swiper";
    import "swiper/css/swiper.min.css";

    import {
        getNewsListPage,
        getBanner,
        getExam,
    } from "@/api/web/web.js";

    export default {
        name: "index",
        props: {
            arrList: Array
        },
        data() {
            return {
                leftActiveIndex: 0,
                examList: [],
                currentDate: new Date(),
            };
        },
        methods: {
            /**
             * 聚合接口 第一次获取数据
             */
            /*getIndex(params).then((res) => {
                 if (res.status) {
                     // 考试
                     let examList =this.examList;
                     examList.forEach((item) => {
                         let date = new Date().getTime();
                         let kssj = item.bmkssj.replace(/-/g, "/");
                         let jssj = item.bmjssj.replace(/-/g, "/");
                         kssj = new Date(kssj).getTime();
                         jssj = new Date(jssj).getTime();
                         if (kssj <= date && jssj >= date) {
                             this.$set(item, "isOpen", 1);
                         } else if (kssj > date) {
                             this.$set(item, "isOpen", 2);
                         } else {
                             this.$set(item, "isOpen", 3);
                         }
                         item.bmkssj =
                             item.bmkssj != null ? item.bmkssj.substring(0, 16) : "";
                         item.bmjssj =
                             item.bmjssj != null ? item.bmjssj.substring(0, 16) : "";
                     });
                     this.examList = examList;
                 }
             });*/
            getIndex() {
                // console.log(this.examList)
                // 考试
                let examList =this.examList;
                examList.forEach((item) => {
                    let date = new Date().getTime();
                    let kssj = item.bmkssj.replace(/-/g, "/");
                    let jssj = item.bmjssj.replace(/-/g, "/");
                    kssj = new Date(kssj).getTime();
                    jssj = new Date(jssj).getTime();
                    if (kssj <= date && jssj >= date) {
                        this.$set(item, "isOpen", 1);

                    } else if (kssj > date) {
                        this.$set(item, "isOpen", 2);
                    } else {
                        this.$set(item, "isOpen", 3);
                    }
                    item.bmkssj =
                        item.bmkssj != null ? item.bmkssj.substring(0, 16) : "";
                    item.bmjssj =
                        item.bmjssj != null ? item.bmjssj.substring(0, 16) : "";
                });
                this.examList = examList;
            },

            /**
             * 打开banner链接
             * */
            openBanner(url) {
                window.open(url, "_blank");
            },
            /**
             * 获取轮播
             */
            getBannerList() {
                getBanner().then((res) => {
                    if (res.status) {
                        let list = res.data;
                        let bannerList = [];
                        let bannerListMiddle = [];
                        let bannerListBottom = [];
                        if (null != list && list.length > 0) {
                            list.forEach((item) => {
                                if (item.bannerType == "index上部") {
                                    bannerList.push(item);
                                }
                                if (item.bannerType == "index中部") {
                                    bannerListMiddle.push(item);
                                }
                                if (item.bannerType == "index底部") {
                                    bannerListBottom.push(item);
                                }
                            });
                        }
                        this.bannerList = bannerList;
                        this.bannerListMiddle = bannerListMiddle;
                        this.bannerListBottom = bannerListBottom;
                    }
                });
            },
            /**
             * 获取考试报名
             */
            getEaxmList() {
                getExam({
                    limit: 6,
                }).then((res) => {
                    if (res.status) {
                        let list = res.data;
                        list.forEach((item) => {
                            if (
                                item.bmkssj < this.currentDate &&
                                item.bmjssj > this.currentDate
                            ) {
                                this.$set(item, "isOpen", "true");
                            } else {
                                this.$set(item, "isOpen", "false");
                            }
                            item.bmkssj =
                                item.bmkssj != null ? item.bmkssj.substring(0, 16) : "";
                            item.bmjssj =
                                item.bmjssj != null ? item.bmjssj.substring(0, 16) : "";
                        });
                        this.examList = list;
                    }
                });
            },

            /**
             * 更多考试
             */
            moreExam() {
                window.location.href = "/examList";
            },
            /**
             * 跳转登录
             */
            goToLogin() {
                window.location.href = "/login";
            },
        },
        filters: {
            /**
             * 兼容ie的多行超出显示省略号
             * value:需要截取的字符串
             * num:需要截取的长度
             */
            ellipsis(value, num) {
                if (value.length > num) {
                    return value.substring(0, num) + "...";
                } else {
                    return value;
                }
            },
        },
        watch: {
            arrList: {
                immediate:true,
                handler(val) {
                    if (val) {
                        this.examList = val
                        this.getIndex()

                    }
                }
            }
        },
        mounted() {
            new Swiper(".swiper-container", {
                pagination: {
                    el: ".school-banner .swiper-pagination",
                    clickable: true,
                },
                loop: true,
                speed: 3000,
                autoplay: {
                    stopOnLastSlide: false,
                    disableOnInteraction: false,
                },
            });
        },
    };
</script>

<style>
    @import "~@/assets/css/common.css";
    @import "~@/assets/css/index.css";
</style>
